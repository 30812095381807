<template>
  <b-sidebar
      id="sidebar-detail"
    v-model="ownControl"
    right
    bg-variant="white"
    lazy
    backdrop
    @hidden="close"
  >
    <b-container>
      <b-row class="mb-1">
        <b-col
          v-if="selectedFile !== {}"
          class="d-flex align-items-center justify-content-center"
        >
          <amg-icon
            size="50"
            :icon="
              selectedFile.url === null
                ? 'CustomFolderIcon'
                : 'CustomFileIcon'
            "
            :class="{ 'text-warning': selectedFile.type === 'Folder' }"
          />
        </b-col>
      </b-row>
      <b-row class="border-bottom border-bottom-2">
        <b-col class="d-flex align-items-center justify-content-center">
          <h3 class="font-weight-bolder">
            {{ selectedFile.file_name }}.{{ selectedFile.extension }}
          </h3>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col>
          <p class="font-medium-1">
            Created: {{ selectedFile.created_at | myGlobal }} by
            {{ selectedFile.user_upload }}
          </p>
        </b-col>
      </b-row>
      <b-row
        v-if="selectedFile.updated_at"
        class="mt-10"
      >
        <b-col>
          <p class="font-medium-1">
            Updated: {{ selectedFile.updated_at | myGlobal }}
          </p>
        </b-col>
      </b-row>
      <b-row
        v-if="selectedFile.type === 'Folder'"
        class="mt-10"
      >
        <b-col>
          <p class="font-medium-1">
            Files: {{ selectedFile.countfiel }}
          </p>
        </b-col>
      </b-row>
      <b-row
        v-else
        class="mt-10"
      >
        <b-col>
          <p class="font-medium-1">
            Size: {{ selectedFile.size }}
          </p>
        </b-col>
      </b-row>
      <b-row class="mt-10">
        <b-col>
          <p class="font-medium-1">
            Permissions:
            <span>
              Rename, Delete,
            </span>
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button
            v-b-toggle.sidebar-detail
            variant="primary"
            class="w-100"
            @click="contentClicked"
          >Open</b-button>
        </b-col>
      </b-row>
    </b-container>
  </b-sidebar>
</template>

<script>
export default {
  props: {
    selectedFile: null,
  },
  data() {
    return {
      ownControl: false,
    }
  },
  async mounted() {
    this.ownControl = true
  },
  methods: {
    contentClicked() {
      this.$emit('contentClicked', this.selectedFile)
    },
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style scoped>

</style>
