<template>
  <b-modal
    v-model="onControl"
    hide-footer
    modal-class="modal-primary"
    title="New Folder"
    centered
    @hidden="close"
  >
    <b-row>
      <b-col>
        <validation-observer ref="form">
          <label>Folder name</label>
          <validation-provider
            v-slot="{ errors }"
            name="folderName"
            rules="required"
          >
            <b-form-input
              v-model="newFolderName"
              @keyup.enter="createFolder"
            />
            <label
              v-if="errors[0]"
              class="text-danger"
            >
              <span>Folder name {{ errors[0] }}</span>
            </label>
          </validation-provider>
        </validation-observer>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="d-flex align-items-center justify-content-end mt-1">
        <b-button
          variant="primary"
          @click="createFolder"
        >
          Create
        </b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import CompaniesService from '@/views/management/views/companies/companies.service'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      newFolderName: '',
      onControl: false,
    }
  },
  created() {
    this.onControl = true
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async createFolder() {
      try {
        const result = await this.$refs.form.validate()
        if (result) {
          const data = await CompaniesService.saveFolderCompany({
            name_folder: this.newFolderName,
            user_id: this.currentUser.user_id,
            company_id: this.$route.params.id,
          })
          if (data.status === 200) {
            this.$emit('reload')
            this.close()
            this.showSuccessSwal()
          }
        }
      } catch (error) {
        this.showErrorSwal(error)
      }
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      token: 'auth/token',
    }),
  },
}
</script>

<style scoped>

</style>
