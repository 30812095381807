<template>
  <div>
    <b-card class="border-top-primary border-3 border-table-radius px-0">
      <div class="d-flex align-items-center justify-content-between">
        <span class="d-flex align-items-center">
          <feather-icon
            icon="HomeIcon"
            size="24"
            class="text-primary cursor-pointer mr-1"
            @click="getFilesFromFolder(null)"
          />
          <span>
            <span
              v-if="selectedFolder.file_name"
              class="font-large-1"
            >/ &nbsp;</span>
            <span class="font-medium-5">{{ selectedFolder.file_name? selectedFolder.file_name : '' }}</span>
          </span>
        </span>
        <b-button
          v-if="!selectedFolder.file_name"
          variant="outline-info"
          @click="newFolderController = true"
        >
          New Folder
        </b-button>
        <b-button
          v-else
          class="mr-1"
          variant="outline-primary"
          @click="newFileController = true"
        >
          Add Files
        </b-button>
      </div>
    </b-card>
    <b-card
      class="p-0"
      body-class="py-0"
    >
      <b-row class="my-1">
        <b-col class="d-flex align-items-center justify-content-end py-0">
          <b-form-radio-group
            v-model="selectedView"
            buttons
            button-variant="outline-primary"
          >
            <b-form-radio
              :value="true"
            >
              <feather-icon
                icon="FolderIcon"
                size="18"
              />
            </b-form-radio>
            <b-form-radio
              :value="false"
            >
              <feather-icon
                icon="ListIcon"
                size="18"
              />
            </b-form-radio>

          </b-form-radio-group>
        </b-col>
      </b-row>
      <b-row
        v-if="selectedView"
        class="mb-4"
      >
        <template v-if="currentFiles.length > 0">
          <b-col
            v-for="(content, index) in currentFiles"
            :key="index"
            cols="6"
            sm="4"
            md="3"
            lg="2"
            xl="2"
            :class="skin === 'dark' ? 'hover-shadow-dark' : 'hover-shadow-light'"
          >
            <file-component-company
              :current-user="currentUser"
              :content="content"
              @contentClicked="contentClicked"
              @details="openFileDetail"
              @deleteFile="deleteCompanyFile"
              @edit="updateCompanyFile"
            />
          </b-col>
        </template>
        <template v-else>
          <div
            v-if="selectedFolder.file_name"
            class="d-flex justify-content-center font-medium-5"
            style="width: 100%"
          >
            You haven't created any file yet, <a
              class="text-primary"
              @click="newFileController = true"
            >&nbsp;create one!</a>
          </div>
          <div
            v-else
            class="d-flex justify-content-center font-medium-5"
            style="width: 100%"
          >
            You haven't created any folder yet, <a
              class="text-primary"
              @click="newFolderController = true"
            >&nbsp;create one!</a>
          </div>
        </template>
      </b-row>
      <b-row
        v-else
        class="mb-1"
      >
        <b-table
          :fields="fields"
          :items="currentFiles"
          table-class="font-medium-1"
          responsive
          show-empty
        >
          <template #empty>
            <div
              v-if="selectedFolder.file_name"
              class="d-flex justify-content-center font-medium-1"
              style="width: 100%"
            >
              You haven't created any file yet, <a
                class="text-primary"
                @click="newFileController = true"
              >&nbsp;create one!</a>
            </div>
            <div
              v-else
              class="d-flex justify-content-center font-medium-1"
              style="width: 100%"
            >
              You haven't created any folder yet, <a
                class="text-primary"
                @click="newFolderController = true"
              >&nbsp;create one!</a>
            </div>
          </template>
          <template #cell(file_name)="data">
            <span
              v-if="selectedFile.id !== data.item.id"
              class="cursor-pointer"
              @click="contentClicked(data.item)"
            >
              <amg-icon
                :icon="
                  data.item.extension ? 'CustomFileIcon' : 'CustomFolderIcon'
                "
                :style="data.item.type === 'Folder' ? 'fill: #ff9f43' : ''"
                class="mr-50"
                :class="{ 'text-warning': data.item.type === 'Folder' }"
                size="15"
              />
              <span>{{
                data.item.file_name +
                  (data.item.extension ? "." + data.item.extension : "")
              }}</span>
            </span>
            <span
              v-else
            >
              <b-form-group>
                <b-form-input
                  v-model="selectedFile.file_name"
                  class="d-inline"
                  style="width: 80%"
                  @keyup.enter="updateCompanyFile(selectedFile)"
                />
                <tabler-icon
                  icon="CheckIcon"
                  size="20"
                  class="text-success ml-1 cursor-pointer"
                  @click="updateCompanyFile(selectedFile)"
                />
                <tabler-icon
                  icon="XIcon"
                  size="20"
                  class="text-danger ml-1 cursor-pointer"
                  @click="selectedFile = []"
                />
              </b-form-group>
            </span>
          </template>
          <template #cell(countfiel)="data">
            <span v-if="!data.item.extension">{{ data.item.countfiel }}</span>
            <span v-else>{{ data.item.size }}</span>
          </template>
          <template #cell(user_upload)="data">
            {{ data.item.user_upload }}<br>
            {{ data.item.created_at | myGlobalWithHour }}
          </template>
          <template #cell(user_modified)="data">
            <div v-if="data.item.user_modified">
              {{ data.item.user_modified }}<br>
              {{ data.item.updated_at | myGlobalWithHour }}
            </div>
          </template>
          <template #cell(actions)="data">
            <feather-icon
              class="text-primary cursor-pointer mr-1"
              icon="EditIcon"
              size="15"
              @click="selectItemToEdit(data.item)"
            />
            <feather-icon
              class="text-danger cursor-pointer"
              icon="TrashIcon"
              size="15"
              @click="deleteCompanyFile(data.item)"
            />
          </template>
        </b-table>
      </b-row>
      <new-folder-company-modal
        v-if="newFolderController"
        @close="newFolderController = false"
        @reload="getFilesByCompany"
      />
      <new-file-company-modal
        v-if="newFileController"
        :folder="selectedFolder"
        @close="newFileController = false"
        @reload="getFilesFromFolder(selectedFolder.id)"
      />
      <detail-file-company-sidebar
        v-if="detailFileController"
        :selected-file="selectedFile"
        @contentClicked="contentClicked"
        @close="closeSidebarDetail"
      />
    </b-card>
  </div>
</template>

<script>
import CompaniesService from '@/views/management/views/companies/companies.service'
import NewFolderCompanyModal from '@/views/management/views/companies/dashboard/files-module/modals/NewFolderCompanyModal.vue'
import NewFileCompanyModal from '@/views/management/views/companies/dashboard/files-module/modals/NewFileCompanyModal.vue'
import { mapGetters } from 'vuex'
import FileComponentCompany from '@/views/management/views/companies/dashboard/files-module/FileComponentCompany.vue'
import DetailFileCompanySidebar from '@/views/management/views/companies/dashboard/files-module/sidebars/DetailFileCompanySidebar'

export default {
  components: {
    NewFolderCompanyModal,
    NewFileCompanyModal,
    FileComponentCompany,
    DetailFileCompanySidebar,
  },
  data() {
    return {
      fields: [
        {
          key: 'file_name',
          label: 'File Name ',
          sortable: true,
          thStyle: {
            width: '400px',
          },
        },
        {
          key: 'countfiel',
          label: 'Files',
          class: 'text-center',
        },
        {
          key: 'user_upload',
          label: 'Created By',
          class: 'text-center',
        },
        {
          key: 'user_modified',
          label: 'Modified By',
          class: 'text-center',
        },
        {
          key: 'actions',
          label: 'Actions',
        },
      ],
      idCompany: '',
      currentFiles: [],
      newFolderController: false,
      detailFileController: false,
      isFolderView: true,
      selectedFolder: [],
      selectedFile: [],
      newNameFile: '',
      newFileController: false,
      selectedView: true,
    }
  },
  async created() {
    this.idCompany = this.$route.params.id
    await this.getFilesByCompany()
    if (this.isFolderView) this.fields[0].label = 'Folder name'
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      token: 'auth/token',
      skin: 'appConfig/skin',
    }),
  },
  methods: {
    async getFilesByCompany() {
      this.addPreloader()
      const data = await CompaniesService.getFilesByCompany({
        idcompany: this.idCompany,
        parent: null,
        date_to: null,
        orderby: 1,
        order: 'desc',
      })
      this.removePreloader()
      this.currentFiles = []
      this.selectedFolder = []
      this.currentFiles = data.data.data
      if (this.currentFiles.length > 0) {
        this.isFolderView = this.currentFiles[0].url === null
      }
      this.fields[0].label = this.isFolderView ? 'Folder name' : 'File name'
    },
    openFileDetail(content) {
      this.selectedFile = content
      this.detailFileController = true
    },
    closeSidebarDetail() {
      this.selectedFile = []
      this.detailFileController = false
    },
    async contentClicked(content) {
      if (content.url === null) {
        this.selectedFolder = content
        await this.getFilesFromFolder(content.id)
      } else {
        window.open(
          content.url,
          '_blank',
        )
      }
    },
    async getFilesFromFolder(folderId) {
      try {
        this.addPreloader()
        const data = await CompaniesService.getFilesByCompany({
          idcompany: this.idCompany,
          parent: folderId,
          date_to: null,
          orderby: 1,
          order: 'desc',
        })
        this.currentFiles = []
        if (folderId === null) {
          this.selectedFolder = []
          this.selectedFolder.id = null
        }
        this.currentFiles = data.data.data
        if (this.currentFiles.length > 0) {
          this.isFolderView = this.currentFiles[0].url === null
        }
        this.fields[0].label = this.isFolderView ? 'Folder name' : 'File name'
        this.fields[1].label = this.isFolderView ? 'Files' : 'Size'
        this.removePreloader()
      } catch (error) {
        this.showErrorSwal(error)
        this.removePreloader()
      }
    },
    async deleteCompanyFile(file) {
      try {
        const response = await this.showConfirmSwal()
        if (response.isConfirmed) {
          const data = await CompaniesService.deleteFileCompany({
            file_id: file.id,
            user_id: this.currentUser.user_id,
          })
          if (data.status === 200) {
            this.showSuccessSwal()
            await this.getFilesFromFolder(this.selectedFolder.id)
          }
        }
      } catch (e) {
        this.showErrorSwal(e)
      }
    },
    async selectItemToEdit(item) {
      this.selectedFile = item
    },
    async updateCompanyFile(item) {
      try {
        const data = await CompaniesService.updateFileCompany({
          file_id: item.id,
          name_file: item.file_name,
          user_id: this.currentUser.user_id,
        })
        if (data.status === 200) {
          this.selectedFile = []
          await this.getFilesFromFolder(this.selectedFolder.id)
          this.showToast(
            'success',
            'top-right',
            'Congratulations',
            'CheckIcon',
            'Renamed successfully',
          )
        }
      } catch (e) {
        this.showErrorSwal(e)
      }
    },
  },
}
</script>

<style scoped>
.hover-shadow-light,
.hover-shadow-dark {
  transition: box-shadow 0.3s;
}
.hover-shadow-dark:hover {
  box-shadow: 0 0 11px #191c24;
}
.hover-shadow-light:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}
</style>
