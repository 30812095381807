<template>
  <b-modal
    v-model="onControl"
    title="Upload File"
    modal-class="modal-primary"
    body-class="p-50"
    button-size="sm"
    ok-title="Ok"
    :hide-footer="files.length === 0"
    centered
    @hidden="close"
  >
    <drag-and-drop
      v-model="files"
      :files-array="files"
    />

    <template #modal-footer>
      <b-button
        variant="primary"
        @click="uploadFile"
      >
        Upload
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import dragAndDrop from '@/views/commons/utilities/DragAndDrop'
import { mapGetters } from "vuex";
import CompaniesService from '@/views/management/views/companies/companies.service'

export default {
  props: {
    folder: null,
  },
  components: {
    dragAndDrop,
  },
  data() {
    return {
      files: [],
      onControl: false,
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      token: 'auth/token',
    }),
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async uploadFile() {
      try {
        const result = await this.showConfirmSwal()
        if (result.isConfirmed) {
          const formData = new FormData()
          this.files.forEach((file) => {
            formData.append("images[]", file, file.name);
          })
          this.addPreloader()
          formData.append('companyid', this.$route.params.id)
          formData.append('folder_name', this.folder.file_name)
          formData.append('user_id', this.currentUser.user_id)
          formData.append('idfolder', this.folder.id)
          const headers = {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: function (progressEvent) {
              this.uploadPercentage = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              )
            }.bind(this),
          }
          const data = await CompaniesService.saveFileCompany(formData, headers)
          if (data.status === 200) {
            this.removePreloader()
            this.$emit('reload')
            this.close()
            this.showSuccessSwal()
          }
        }
      } catch (e) {
        this.removePreloader()
        this.showErrorSwal(e)
      }
    },
  },

  created() {
    this.onControl = true
  },
}
</script>

<style scoped>

</style>
